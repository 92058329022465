import * as React from "react";
import {graphql, HeadFC} from "gatsby";
import {SEO} from "../components/Seo/seo";
import Container from "../components/Container/container";
import Section from "../components/Section/section";
import {SectionHeader} from "../components/Section/SectionHeader/section-header";
import {useTranslation} from "react-i18next";

const CookiePolicyPage = () => {
    const {t} = useTranslation();
    return (
        <Section sectionId={"cookie-policy"}>
            <SectionHeader text={"Polityka Prywatności i Polityka Cookies"} highlightedText={""}/>
            <Container>
                <p style={{textAlign: "justify"}}>
                    <h2>Polityka prywatności</h2>
                    Administratowem Twoich danych osobowych jest Markana Marek Żyła z siedzibą przy ul. Frysztackiej 130, 43-400 Cieszyn, NIP: 5482745763, REGON: 522848750.
                    Przetwarzamy Twoje dane osobowe zgodnie z postanowieniami Rozporząrzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016 w sprawie ochrony osób
                    fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
                    ochronie danych (zw. RODO)

                    We wszelkich sprawach związanych z ochroną danych osbowych oraz w celu skorzystania z przysługujących praw, użytkownik może skontaktować się z Nami drogą
                    mailową wysyłając wiadomość na adres contact@markana.it, lub listownie na adres Frysztacka 130, 43-400 Cieszyn. W obu przypadkach użytkownik powinien
                    zatytułować wiadomość tytułem "RODO".

                    <h2>Polityka Cookies</h2>
                    <h3>Co to są pliki Cookies</h3>
                    Cookies to pliki tekstowe instalowane na urządzeniu użytkownika przeglądającego stronę internetową. Jest to powszechnie stosowana technologia służąca do
                    identyfikacji użytkownika i zapewnienia treści o najwyższej jakości. Ciasteczka nie wpływają negatywnie na sposób działania urządzenia użytkownika
                    przeglądającego
                    stronę internetową.

                    <h3>Pliki Cookies na naszej stronie internetowej</h3>
                    Na naszej stronie internetowej nie stosujemy plików cookies, jednak korzystamy z technologii Local Storage w celu zapamiętania preferencji użytkownika
                    dotyczącego
                    zapisywania ciasteczek na swoim urządzeniu. Aby zaprzestać działania technologii Local Storage użytkownik powinien ją wyłączyć w stosownych ustawieniach
                    używanej
                    przez niego przeglądarki internetowej.

                    <h3>Zarządzanie plikami Cookies</h3>
                    Jeżeli chcesz zmienić swoje preferencje dotyczące plików Cookies, powinieneś skorzystać ze stosownego poradnika właściwego dla przeglądarki internetowej, której
                    aktualnie używasz. W ustawieniach Twojej przeglądarki internetowej możesz wyłączyć możliwości śledzenia aktywności użytkownika czy czyścić ciasteczka regularnie
                    w
                    określonych interwałach czasowych.

                    <h2>Postanowienia końcowe</h2>
                    Akceptacja Polityki Prywatności i Polityki Cookies jest jednocześnie akceptacją najnowszej wersji tej Polityki niniejszej strony internetowej. Zastrzegamy sobie
                    prawo do wprowadzenia zmian w brzmieniu, jak i wprowadzenia lub usunięcia używanych na stronie internetowej technologii, w tym ciasteczek firm trzecich. Po
                    wprowadzeniu ewentualnych zmian niniejszy dokument zostanie zaktualizowany o swosowne zapisy. Użytkownik ma prawo do rezygnacji z akceptacji Polityki, w tym
                    celu
                    należy wyczyścić magazyn Local Storage przeglądarki.
                    <br/><br/>
                    Aktualne brzmienie polityki cookie, jest dostępne pod adresem <a href="https://markana.it/privacy-policy">https://markana.it/privacy-policy</a> <br/>
                    Aktualna wersja: 10.10.2022.
                </p>
            </Container>
        </Section>
    );
};

export default CookiePolicyPage;

export const Head: HeadFC = () => <SEO/>

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;